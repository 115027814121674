// For media chrome styling reference:
// https://www.media-chrome.org/docs/en/reference/styling
const mediaControllerStyles = {
  aspectRatio: '16/9',
  '--media-tooltip-display': 'none'
};

const noHoverColorStyles = {
  '--media-control-hover-background': 'transparent'
};

const topChromeStyles = {
  aspectRatio: '16/9',
  '--media-control-padding': '5px'
};

const centeredChromeStyles = {
  borderRadius: '50%',
  '--media-control-padding': '48px',
  '--media-control-height': '48px'
};

const posterImageStyles = {
  '--media-object-fit': 'cover'
};

const controlBarStyles = {
  '--media-secondary-color': 'transparent'
};

const loaderStyles = {
  '--media-loading-indicator-icon-width': '200px',
  '--media-loading-indicator-icon-height': '200px',
  '--media-primary-color': 'white'
};

const timeDisplayStyles = {
  '--media-font-family': 'Inter, sans-serif'
};

const timeRangeStyles = {
  '--media-range-thumb-opacity': '0',
  '--media-range-track-border-radius': '10px',
  '--media-preview-time-background': 'white',
  '--media-preview-time-border-radius': '4px',
  '--media-preview-time-padding': '4px 14px',
  '--media-text-color': 'black',
  '--media-preview-time-text-shadow': 'none',
  '--media-preview-time-margin': '0 0 -4px 0',
  '--media-time-range-buffered-color': 'rgba(255, 255, 255, 0.12)'
};

const volumeRangeStyles = {
  width: '100%',
  '--media-control-background': 'transparent',
  '--media-range-thumb-opacity': '0',
  '--media-range-track-height': '8px',
  '--media-range-track-width': '100%',
  '--media-range-track-background': 'rgba(18, 18, 18, 0.12)',
  '--media-range-track-border-radius': '10px',
  '--media-range-bar-color': 'rgb(236 16 21)',
  '--media-range-padding-left': 0,
  '--media-range-padding-right': 0
};

const seekIconStyles = {
  '--media-control-height': '1.75rem'
};

export {
  centeredChromeStyles,
  controlBarStyles,
  loaderStyles,
  mediaControllerStyles,
  noHoverColorStyles,
  posterImageStyles,
  seekIconStyles,
  timeDisplayStyles,
  timeRangeStyles,
  topChromeStyles,
  volumeRangeStyles
};
