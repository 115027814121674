import cx from 'classnames';
import { MediaController, MediaPlayButton } from 'media-chrome/react';
import { AnimatePresence, motion } from 'motion/react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Suspense, useMemo, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import shallow from 'zustand/shallow';

import EmbedDetails from 'components/pages/embed';
import PlayButton from 'components/play-button';
import * as styles from 'components/video-player/InternalPlayer/Player.styles';
import useVideoPlayerStore from 'components/video-player/store';

import getImageFromSet from 'lib/get-image-from-set';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import { VideoData } from 'lib/pages/talks/slug/[...slug].props';

import { crush } from '@tedconf/js-crushinator-helpers';
import { PhotoSize } from 'api';
import { useTalkPageContext } from './talk-page-context';

import Cover from 'components/cover';
import Loader from 'components/loaders';
import TedLogo from 'icons/TedLogoIcon';

const VideoPlayerPlaceholder = ({ image }: { image: string }) => {
  return (
    <div className="aspect-video relative w-full bg-black">
      <div className="absolute inset-0">
        <Cover imageUrl={image} />
      </div>
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="bg-black/85 rounded-full p-6">
          <Loader.Spinner size="22" color="white" />
        </div>
      </div>
    </div>
  );
};

/**
 * Using ssr: false for VideoPlayer is necessary because:
 * 1. It relies on browser-only APIs (window, HTMLVideoElement, play/pause methods)
 * 3. It initializes event listeners and refs that could cause hydration mismatches
 * 4. It accesses user activation state which is only available in browsers
 * 5. Performance: video player initialization is heavy and better deferred to client
 */
const VideoPlayer = dynamic(() => import('components/video-player'), {
  ssr: false,
  loading: () => null
});

/**
 * Using ssr: false for EndCard is necessary because:
 * 1. It depends on video player state
 * 2. It uses browser APIs for interactions
 * 3. It's shown conditionally at the end of video playback
 */
const EndCard = dynamic(() => import('./EndCard'), {
  ssr: false,
  loading: () => null
});

/**
 * Using ssr: false for SubtitleCredits is necessary because:
 * 1. It's related to video subtitles which are loaded client-side
 * 2. It depends on video player state
 */
const SubtitleCredits = dynamic(
  () =>
    import('components/video-player/InternalPlayer/Subtitles/subtitle-credits'),
  {
    ssr: false,
    loading: () => null
  }
);

type HeroProps = {
  video: VideoData;
  embed?: boolean;
};

const MIN_VIDEO_WIDTH = 640;

const Hero = ({
  video,
  embed = false,
  ref
}: HeroProps & {
  ref?: React.RefObject<HTMLDivElement>;
}): React.ReactNode => {
  const { query } = useRouter();
  const [hovered, setHovered] = useState(false);
  const { playlistSlug } = useTalkPageContext();

  const hideControls = query.hideControls === 'true';
  const hideDetails = query.hideDetails === 'true';

  const image = useMemo(() => {
    if (!video?.primaryImageSet?.length) return '';

    const rawImage = getImageFromSet(
      video.primaryImageSet as PhotoSize[],
      '16x9'
    );
    if (!rawImage) return '';

    return crush(rawImage, {
      width: 1600,
      height: 900,
      quality: 85,
      format: 'webp'
    });
  }, [video?.primaryImageSet]);

  const removeMinWidth = useIsBreakpointWidth({
    size: 'lg'
  });

  const isMobileDevice = useMemo(() => isAndroid || isIOS, []);
  const isMobileWeb = useIsBreakpointWidth({
    size: 'sm',
    breakPointType: 'tui'
  });
  const isMobile = isMobileDevice || isMobileWeb;

  const { playbackMode, ready, contentState, shouldAutoplay } =
    useVideoPlayerStore(
      useMemo(
        () => state => ({
          playbackMode: state.playbackMode,
          ready: state.ready,
          contentState: state.contentState,
          shouldAutoplay: state.shouldAutoplay
        }),
        []
      ),
      shallow
    );

  const videoWrapperClasses = cx(
    ready ? 'pointer-events-auto' : 'pointer-events-none'
  );

  const videoLoaderClasses = cx(
    'video-player-loader pointer-events-none absolute z-10 flex size-full items-center justify-center',
    {
      hidden: playbackMode === 'ended'
    }
  );

  const { canonicalUrl, speakerFirstName, speakerLastName } = useMemo(() => {
    if (!video?.playerData) {
      return { canonicalUrl: '', speakerFirstName: '', speakerLastName: '' };
    }

    try {
      const playerData = JSON.parse(video.playerData);
      return {
        canonicalUrl: playerData.canonical || '',
        speakerFirstName: video?.speakers?.nodes?.[0]?.firstname || '',
        speakerLastName: video?.speakers?.nodes?.[0]?.lastname || ''
      };
    } catch (error) {
      console.error('Error parsing playerData JSON:', error);
      return { canonicalUrl: '', speakerFirstName: '', speakerLastName: '' };
    }
  }, [video?.playerData, video?.speakers?.nodes]);

  const showPoster =
    playbackMode === 'unstarted' ||
    (playbackMode === 'playing' && !ready) ||
    (playbackMode === 'paused' && !ready);

  return (
    <AnimatePresence>
      <motion.div
        className={cx('w-full overflow-hidden', {
          'aspect-h-9 sticky top-0 z-20 bg-white lg:relative lg:top-[unset] lg:block':
            !embed,
          'relative h-screen max-h-screen min-h-screen': embed
        })}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div
          ref={ref}
          className={cx('relative', {
            'size-full': embed
          })}
        >
          {!hideControls && (
            <div
              className={cx('md:hidden', {
                'absolute top-0 z-40 w-full text-sm md-tui:text-base': embed
              })}
            >
              <SubtitleCredits />
            </div>
          )}

          <MediaController
            autohide={isMobile ? '2' : '-1'}
            noAutohide
            defaultStreamType="ondemand"
            style={{
              ...styles.mediaControllerStyles,
              minWidth: removeMinWidth ? 0 : MIN_VIDEO_WIDTH,
              width: '100%',
              height: '100%'
            }}
            className={cx({
              'size-full': !embed,
              'h-screen w-screen': embed
            })}
          >
            {!hideDetails && embed && !isMobile && (
              <EmbedDetails
                video={{
                  speaker: {
                    firstName: speakerFirstName,
                    lastName: speakerLastName
                  },
                  title: video.socialTitle || video.title,
                  url: canonicalUrl
                }}
                hovered={hovered}
              />
            )}

            {playbackMode === 'ended' && !embed && (
              <>
                <div className="bg-opacity-75 absolute bottom-0 left-0 right-0 top-0 z-10 size-full bg-black text-white">
                  <EndCard
                    currentVideoId={video.id}
                    currentPlaylistId={playlistSlug || ''}
                  />
                </div>
              </>
            )}

            {(playbackMode === 'unstarted' ||
              ((playbackMode !== 'ended' || isMobile) &&
                contentState === 'none')) && (
              <div
                slot="centered-chrome"
                id="media-wrapper"
                className={videoWrapperClasses}
                style={styles.centeredChromeStyles}
              >
                <MediaPlayButton
                  aria-label="play"
                  tabIndex={0}
                  role="button"
                  mediaPaused={playbackMode === 'paused'}
                >
                  <span slot="icon">
                    <PlayButton />
                  </span>
                </MediaPlayButton>
              </div>
            )}

            {showPoster && (
              <div slot="poster" className="relative">
                {image ? (
                  <Cover imageUrl={image} />
                ) : (
                  <div className="flex size-full items-center justify-center">
                    <TedLogo className="h-20" />
                  </div>
                )}
              </div>
            )}

            {!embed && shouldAutoplay && (
              <div className={videoLoaderClasses}>
                <Loader.Spinner size="22" color="white" />
              </div>
            )}

            <Suspense fallback={<VideoPlayerPlaceholder image={image} />}>
              <VideoPlayer
                key={video.id}
                video={video}
                hovered={hovered}
                poster={image}
                hideControls={hideControls}
              />
            </Suspense>
          </MediaController>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Hero;
