import cx from 'classnames';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

import TedLogo from 'icons/TedLogoIcon';
import { Link } from 'components/router';
import useVideoPlayerStore from 'components/video-player/store';

type EmbedDetailsProps = {
  video: {
    speaker: {
      firstName: string;
      lastName: string;
    };
    title: string;
    url: string;
  };
  hovered: boolean;
};

export default function EmbedDetails({
  video: {
    speaker: { firstName, lastName },
    title,
    url
  },
  hovered
}: EmbedDetailsProps) {
  const router = useRouter();
  const isMobileDevice = isAndroid || isIOS;
  const isMobileWeb = useIsBreakpointWidth({
    size: 'sm',
    breakPointType: 'tui'
  });
  const isMobile = isMobileDevice || isMobileWeb;

  const { contentState, isAdBreak, subtitleCues, playbackMode } =
    useVideoPlayerStore(state => ({
      contentState: state.contentState,
      isAdBreak: state.isAdBreak,
      subtitleCues: state.subtitleCues,
      playbackMode: state.playbackMode
    }));

  const hasStarted = playbackMode === 'playing' || playbackMode === 'paused';

  // Determine classes for animations and transitions
  const animateVisibleClasses = useMemo(
    () =>
      cx('transition-opacity duration-300 ease-in-out', {
        'delay-3000 opacity-0': !hovered && !isMobile,
        'opacity-100': hovered && !isMobile
      }),
    [hovered, isMobile]
  );

  const displaySubtitles = useMemo(
    () => router.query.subtitle && subtitleCues.length > 0 && !isAdBreak,
    [router.query.subtitle, subtitleCues, isAdBreak]
  );

  if (playbackMode === 'ended') return null;

  return (
    <>
      {hasStarted ? (
        // Since the video will be in an iframe, we need to open the link in a new tab
        <Link isZenithLink={false} target="_blank" href={url}>
          <div
            className={cx(
              'absolute left-5 z-30 w-[100px] md-tui:top-7',
              animateVisibleClasses,
              {
                hidden: contentState !== 'content',
                'top-5': !displaySubtitles || router.query.subtitle === 'en',
                'top-16': displaySubtitles && router.query.subtitle !== 'en'
              }
            )}
          >
            <TedLogo />
          </div>
        </Link>
      ) : (
        <div className="pointer-events-none absolute bottom-7 z-30 w-full px-6">
          <div className="flex w-full items-end justify-between gap-3 md-tui:items-center">
            <div className="flex flex-col gap-3">
              <p className="text-tui-lg font-bold leading-none text-white md-tui:text-tui-7xl">
                {firstName} {lastName}
              </p>
              <p className="text-tui-sm leading-none text-white md-tui:text-tui-base">
                {title}
              </p>
            </div>

            <Link
              href={url}
              isZenithLink={false}
              target="_blank"
              className="pointer-events-auto"
            >
              <div className="w-16 md-tui:w-24">
                <TedLogo />
              </div>
            </Link>
          </div>
        </div>
      )}
    </>
  );
}
